import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { get } from '@docavenue/core';
import MenuItemDivider from './MenuItemDivider';

const Suggestion = suggestionProps => {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    renderItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem ? selectedItem.id === suggestion.id : false;
  const highlightedText = ['name', 'firstName', 'lastName'].reduce<
    Array<string>
  >((text, key) => {
    const value = get(suggestion, `_highlightResult.${key}.value`);
    if (value) {
      text.push(value);
    }
    return text;
  }, []);
  return (
    <>
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        disabled={suggestion?.disabled}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
        divider={suggestion.id === 'suggestion_tlc'}
      >
        {renderItem ? (
          renderItem(suggestion, highlightedText, index)
        ) : (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: highlightedText.join(' ') || suggestion.label,
            }}
          />
        )}
      </MenuItem>
      {suggestion.divider && <MenuItemDivider />}
    </>
  );
};

export default Suggestion;
